.container {
    display: flex;
    justify-content: space-between;
}

.left {
    align-self: end;
    
    img {
        margin: 0 8px;
        
        &:hover {
            transform: translate(1px, 1px);
        }
    }
}

.center {
    text-align: center;

    div {
        margin: 14px 0 26px;
        font-family: Arvo;
        font-size: 12px;
        letter-spacing: 1.5px;
        font-weight: bold;
        align-self: center;
        color: #F8F8F8;
    }

    p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
    }
}

.right {
    align-self: end;

    a {
        text-decoration: none;
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 47px;
    }
}