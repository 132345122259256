.div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .8);
    z-index: 9;
}

.modal {
    position: absolute;
    width: 451px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(180deg, #0D0F11 0%, #2E1E50 100%);
    border: 1px solid var(--pink);

    h6 {
        font-family: Arvo;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        padding: 40px 40px 21px;
        border-bottom: 1px solid var(--pink);
        color: #F8F8F8;
    }
}

.steps {
    padding: 40px;
}

.step {
    margin-bottom: 32px;
    display: flex;

    img {
        width: 20px;
        height: 20px;
        margin: 3px 10px 0 0;
    }

    h5 {
        font-family: Arvo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        background: -webkit-linear-gradient(0deg, #C5614D, #E48C7B);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 8px;
    }

    p {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
    }
}

@media (max-width: 768px) {
    .modal {
        width: 300px;
        margin-top: 50px;
    }
}