.div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
        display: flex;
        align-self: center;

        img {
            width: 34px;
            height: 35px;
        }

        h6 {
            font-family: Arvo;
            font-size: 19px;
            font-weight: bold;
            align-self: center;
            margin-left: 20px;
            color: #F8F8F8;
            letter-spacing: 1.5px;
        }
    }
}

@media (max-width: 768px) {
    .div {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > div {
            display: flex;
            align-self: center;

            img {
                width: 23px;
                height: 24px;
            }
        }

        & > img {
            width: 25px;
            height: 24px;
        }

        h6 {
            font-size: 17px !important;
            margin-left: 8px !important;
        }
    }
    
}