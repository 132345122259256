.third {
    display: flex;
    justify-content: center;
    padding: 167px 0 0;
    min-height: 900px;
}

.bg {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 1000px;
    margin-left: 0;
    z-index: -1;
}

.accordions {
    display: block;

    h3 {
        font-family: Arvo;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 48px;
    }
}

@media (max-width: 768px) {
    .third {
        min-height: 1150px;
    }

    .bg {
        margin-top: 340px;
    }

    .accordions {
        h3 {
            font-family: Arvo;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 44px;
        }
    }
}