.div {
    max-width: 400px;
    height: auto;
    border: 4px solid #F8F8F8;
    padding: 32px 25px;
    background: #2E1E50;

    &>h6 {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
    }

    .pdf {
        width: 346px;
        height: 340px;
    }

    & > p {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 24px 0 16px;
    }
}

.audio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
        margin-right: 20px;
    }
}

@media (max-width: 768px) {
    .div {
        & > div {
            width: auto;
        }

        ._df_book {
            min-width: auto;
        }
    }
}